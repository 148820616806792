import { Alert, Button, Form, Row, Stack } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import i18next from "i18next";

import fetch from "../../fetch";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import useGlobalStore from "../../globalState";

type FormValues = {
  email: string;
  password: string;
};

const loginSchema = yup
  .object({
    email: yup
      .string()
      .email(i18next.t("login.validemail"))
      .required(i18next.t("login.provideemail")),
    password: yup
      .string()
      .min(6, i18next.t("login.tooshortpassword"))
      .required(i18next.t("login.providepassword")),
  })
  .required();

const LoginForm = () => {
  const navigate = useNavigate();
  const [isSubmitting, setSubmitting] = useState(false);
  const [authErrorCode, setAuthErrorCode] = useState(0);
  const { t } = useTranslation();
  const login = useGlobalStore((s) => s.logIn);
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: yupResolver(loginSchema),
  });

  useEffect(() => {
    const checkIfLoggedIn = async () => {
      try {
        const isLoggedIn = useGlobalStore.getState().isLoggedIn;
        if (!isLoggedIn) {
          return;
        }

        await fetch(process.env.REACT_APP_API_URL + "/requests_me");
        navigate("/success");
      } catch (e: any) {
        if (e.status === 404) navigate("/request");
        else useGlobalStore.getState().logOut();
      }
    };
    checkIfLoggedIn();
  }, [navigate]);

  const onSubmit = async (values: any) => {
    try {
      setAuthErrorCode(0);
      setSubmitting(true);
      const fetchResult = await fetch(
        process.env.REACT_APP_API_URL + "/login",
        {
          method: "POST",
          body: JSON.stringify(values),
        }
      );
      const fetchBody = await fetchResult.json();
      localStorage.setItem("auth_token", fetchBody.token);
      localStorage.setItem("refresh_token", fetchBody.refresh_token);
      login();

      try {
        await fetch(process.env.REACT_APP_API_URL + "/requests_me");
        navigate("/success");
      } catch (e) {
        navigate("/request");
      }
    } catch (e: any) {
      setSubmitting(false);
      setAuthErrorCode(e.status);
    }
  };

  return (
    <>
      <Row>
        <h1>{t("submitQuestion")}</h1>
        <h4>{t("createAccountHint")}</h4>
        <Link to="/register">
          <Button disabled={isSubmitting}>{t("login.signup")}</Button>
        </Link>
      </Row>
      <br />
      <hr /> <br />
      <Row>
        <Form
          onSubmit={handleSubmit(onSubmit)}
          style={{ maxWidth: "768px" }}
          className="mx-auto"
        >
          {!!authErrorCode && (
            <Alert key={authErrorCode} variant="danger">
              {authErrorCode < 500 && t("login.authusererror")}
              {authErrorCode >= 500 && t("login.authservererror")}
            </Alert>
          )}
          <Form.Group className="mb-3">
            <Form.Label>{t("login.email")}</Form.Label>
            <Form.Control {...register("email")} isInvalid={!!errors.email} />
            <Form.Control.Feedback type="invalid">
              {errors.email?.message}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>{t("login.password")}</Form.Label>
            <Form.Control
              {...register("password")}
              type="password"
              isInvalid={!!errors.password}
            />
            <Form.Control.Feedback type="invalid">
              {errors.password?.message}
            </Form.Control.Feedback>
          </Form.Group>

          <Stack
            direction="vertical"
            gap={3}
            className="justify-content-evenly"
          >
            <Button disabled={isSubmitting} type="submit">
              {t("login.signin")}
            </Button>
            <Button variant="link" href="/reset-password">
              {t('login.forgotPassword')}
            </Button>
          </Stack>
        </Form>
      </Row>
    </>
  );
};

export default LoginForm;
