import create from "zustand";
import {Dorm} from "./models/Dorm";
import {Question} from "./models/Question";
import jwt_decode from "jwt-decode";
import fetch from "./fetch";
import i18next from "i18next";

interface JWTToken {
    roles: string[];
}

interface GlobalState {
    isLoggedIn: boolean;
    roles: string[];
    logOut: () => void;
    logIn: () => void;
    hasPermission: (permission: string) => boolean;
    settings: { [settingKey: string]: boolean };
    getSettings: () => void;
}

const initProps: () => { roles: string[] } = () => {
    const token = localStorage.getItem("auth_token");
    if (!token) {
        return {roles: []};
    }
    const decodedToken = jwt_decode<JWTToken>(token);
    return {roles: decodedToken.roles};
};

const useGlobalStore = create<GlobalState>((set, get) => ({
    isLoggedIn: !!localStorage.getItem("auth_token"),
    settings: {},
    getSettings: async () => {
        try {
            const settings = await fetch(`${process.env.REACT_APP_API_URL}/settings`);
            const settingsResp = await settings.json();
            const finalSettings: { [key: string]: boolean } = {};
            for (const setting of settingsResp) {
                finalSettings[setting.text] = setting.enabled;
            }
            set({ settings: finalSettings });
        } catch (e: any) {
            alert(i18next.t("connectionError"))
        }
    },
    logIn: () => set({isLoggedIn: true, ...initProps()}),
    logOut: () =>
        set(() => {
            localStorage.removeItem("auth_token");
            initProps();
            window.location.href = "/login";
            return {isLoggedIn: false};
        }),
    hasPermission: (perm) => {
        const permissions = ["ROLE_USER", "ROLE_COMISIE", "ROLE_ADMIN"];
        for (const userPerm of get().roles) {
            if (permissions.indexOf(userPerm) >= permissions.indexOf(perm)) {
                return true;
            }
        }
        return false;
    },
    ...initProps(),
}));

interface AnswerStore {
    answers: { [qId: number]: any };
    setAnswer: (qId: number, value: any) => void;
    setAnswers: (answer: { [qId: number]: any }) => void;
}

const useAnswerStore = create<AnswerStore>((set, get) => ({
    answers: {},
    setAnswer: (qId, answer) =>
        set(() => {
            const existingAnswers = get().answers;
            return {answers: {...existingAnswers, [qId]: answer}};
        }),
    setAnswers: (newAnswers) =>
        set(() => {
            const existingAnswers = get().answers;
            return {answers: {...existingAnswers, ...newAnswers}};
        }),
}));

interface DormStore {
    dorms: Dorm[];
    setDorms: (dorms: Dorm[]) => void;
    deletedDormIds: { [id: number]: boolean };
    resetDeletedDorms: () => void;
    addDeletedDorm: (dorm: Dorm) => void;
    removeDeletedDorm: (dorm: Dorm) => void;
}

const useDormStore = create<DormStore>((set, get) => ({
    dorms: [],
    setDorms: (newDorms) =>
        set(() => {
            return {dorms: newDorms};
        }),
    deletedDormIds: {},
    resetDeletedDorms: () => set({deletedDormIds: {}}),
    addDeletedDorm: (dorm) =>
        set({deletedDormIds: {...get().deletedDormIds, [dorm.id]: true}}),
    removeDeletedDorm: (dorm) =>
        set(() => {
            const deleted = get().deletedDormIds;
            delete deleted[dorm.id];
            return {deletedDormIds: deleted};
        }),
}));

interface RequestStore {
    stepQuestions: { [step: number]: Question[] };
    setStepQuestions: (step: number, questions: Question[]) => void;
    answers: { [id: number]: any };
    setAnswer: (questionId: number, value: any) => void;
    showQuestions: { [id: number]: boolean };
    setShowQuestion: (questionId: number, value: boolean) => void;
    step: number;
    nextStep: () => void;
    prevStep: () => void;
}

const useRequestStore = create<RequestStore>((set, get) => ({
    stepQuestions: {},
    setStepQuestions: (step, questions) =>
        set(() => {
            const existingQuestions = get().stepQuestions;
            return {stepQuestions: {...existingQuestions, [step]: questions}};
        }),
    answers: {},
    setAnswer: (id, value) =>
        set(() => {
            const existingAnswers = get().answers;
            return {answers: {...existingAnswers, [id]: value}};
        }),
    showQuestions: {},
    setShowQuestion: (id, value) =>
        set(() => {
            const existingShowQuestions = get().showQuestions;
            return {showQuestions: {...existingShowQuestions, [id]: value}};
        }),
    step: 0,
    nextStep: () =>
        set(() => {
            const currentStep = get().step;
            if (currentStep >= 5) {
                return {step: currentStep};
            }
            return {step: currentStep + 1};
        }),
    prevStep: () =>
        set(() => {
            const currentStep = get().step;
            if (currentStep === 0) {
                return {step: currentStep};
            }
            return {step: currentStep - 1};
        }),
}));

export default useGlobalStore;
export {useRequestStore, useAnswerStore, useDormStore};
