import { Container, Nav, Navbar } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import useGlobalStore from "./globalState";
// import { changeLanguage } from "i18next";

const NaviBar = () => {
 const { t } = useTranslation();
  const isLoggedIn = useGlobalStore((s) => s.isLoggedIn);
  const logout = useGlobalStore((s) => s.logOut);
  const hasPerm = useGlobalStore((s) => s.hasPermission);


  


  return (
    <Navbar variant="dark" bg="dark" className="mb-3" expand="lg">
      <Container>
        <Navbar.Brand>{t("title")}</Navbar.Brand>
        <Navbar.Toggle aria-controls="navbar-tog" />
        <Navbar.Collapse id="navbar-tog">
          <Nav>
            {!isLoggedIn && (
              <>
                <Nav.Link href="/login">{t("nav.login")}</Nav.Link>
                <Nav.Link href="/register">{t("nav.signup")}</Nav.Link>
              </>
            )}
            {isLoggedIn && (
              <>
                {hasPerm("ROLE_COMISIE") && (
                  <Nav.Link href="/comisie/view">
                    {t("nav.viewrequests")}
                  </Nav.Link>
                )}
                {hasPerm("ROLE_ADMIN") && (
                    <Nav.Link href="/comisie/settings">
                      {t("nav.settings")}
                    </Nav.Link>
                )}
                {hasPerm("ROLE_COMISIE") && (
                    <Nav.Link href="/comisie/dorms">
                      {t("nav.dorms")}
                    </Nav.Link>
                )}
                {hasPerm("ROLE_COMISIE") && (
                    <Nav.Link href="/comisie/rounds">
                      {t("nav.repartitions")}
                    </Nav.Link>
                )}
                <Nav.Link onClick={logout}>{t("nav.logout")}</Nav.Link>
              </>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NaviBar;
