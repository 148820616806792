import { Alert } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import RequestDetails from "../../components/Request/RequestDetails";
import useQuery from "../../useQuery";

const ViewRequest = () => {
  const { t } = useTranslation();
  const query = useQuery();
  const isnew = query.get("isnew");

  return (
    <>
      {!!isnew && (
        <Alert className="m-3" variant="success">
          {t("request.congrats")}
        </Alert>
      )}
      <h1>{t("comisie.requests.view")}</h1>
      <RequestDetails />
    </>
  );
};

export default ViewRequest;
