import useGlobalStore from "./globalState";

const ourfetch = async (url: string, fetchProps: any = {}): Promise<Response> => {
    const authToken = localStorage.getItem("auth_token");

    const headers: any = {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
        ...fetchProps.headers,
    };

    if (!authToken) {
        delete headers["Authorization"];
    }

    const reqUrl = url.startsWith("/")
        ? process.env.REACT_APP_API_URL + url
        : url;

    const result = await fetch(reqUrl, {
        ...fetchProps,
        headers,
    });
    if (result && result.ok) {
        return result;
    }

    if (result.status === 401) {
        const reloginReq = await fetch(process.env.REACT_APP_API_URL + '/login/refresh', {
            method: "POST",
            body: JSON.stringify({
                refresh_token: localStorage.getItem("refresh_token")
            }),
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            }
        })
        if (reloginReq.status === 401) {
            useGlobalStore.getState().logOut();
        } else {
            const reloginBody = await reloginReq.json();
            localStorage.setItem("refresh_token", reloginBody.refresh_token);
            localStorage.setItem("auth_token", reloginBody.token);
            return ourfetch(url, fetchProps);
        }

    }
    throw result;
};

export default ourfetch;
