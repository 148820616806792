import DormList from "./DormList";
import { Row, Col } from "react-bootstrap";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import fetch from "../../fetch";
import { useDormStore } from "../../globalState";
import DormListRequest from "./DormListRequest";

const DormPicker = (props: {requester: string}) => {

  const { t } = useTranslation();
  const [error, setError] = useState(false);
  const allDormList = useDormStore((s) => s.dorms);
  const setDorms = useDormStore((s) => s.setDorms);

  useEffect(() => {
    const fetchDorms = async () => {
      try {
        const dormResults = await fetch(
          process.env.REACT_APP_API_URL + "/dorms"
        );
        const jsonDorms = await dormResults.json();
        setDorms(jsonDorms);
      } catch (e) {
        setError(true);
      }
    };

    if (!allDormList || allDormList.length === 0) {
      fetchDorms();
    }
  }, [allDormList, setDorms, setError]);

  if (error) {
    return <p>{t("request.dormError")}</p>;
  }

  if (!allDormList) {
    return <p>{t("request.dormLoading")}</p>;
  }
  if(props.requester === "1")
  return (
    <Row>
      <Col>
        <DormList dorms={allDormList} setDorms={setDorms} id="all-drop" />
      </Col>
    </Row>
  );
  else return (
    <Row>
      <Col>
        <DormListRequest dorms={allDormList} setDorms={setDorms} id="all-drop" />
      </Col>
    </Row>
  );
};

export default DormPicker;
