import { Dorm } from "../../models/Dorm";
import DormListItem from "./DormListItem";
import { ReactSortable } from "react-sortablejs";
const DormListRequest = (props: {
  id: string;
  dorms: Dorm[];
  setDorms: (dorms: Dorm[]) => void;
}) => {
    const dormer = props.dorms.filter(dorms => {return dorms.name !== "request.grozavestiD" && dorms.name !== "request.kogalniceanuB" && dorms.name !== "request.leu"});
  return (
    <ReactSortable list={dormer} delayOnTouchOnly={true} delay={500} setList={props.setDorms}>
      {dormer.map((dorm) => {
       
       return <DormListItem dorm={dorm} key={dorm.id} />
     
})}
    </ReactSortable>
  );
};

export default DormListRequest;
