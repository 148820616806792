import {Button, Col, Container, Form, Image, Row} from "react-bootstrap";
import logo from '../assets/logo.webp';
import {useState} from "react";
import * as yup from "yup";
import i18next from "i18next";
import fetch from '../fetch';
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {useTranslation} from "react-i18next";
import {useNavigate, useParams} from "react-router-dom";

type ResetFormValues = {
    email: string;
};

type ChangeFormValues = {
    newPassword: string;
}

const resetPasswordSchema = yup
    .object({
        newPassword: yup
            .string()
            .min(6, i18next.t("login.tooshortpassword"))
            .required(i18next.t("login.providepassword")),
    })
    .required();

const newPasswordSchema = yup
    .object({
        email: yup
            .string()
            .email(i18next.t("login.validemail"))
            .required(i18next.t("login.provideemail"))
    })
    .required();

const PasswordReset = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(false);
    const query = useParams();
    const resetId = query.id;
    console.log(resetId)
    const {
        handleSubmit,
        register,
        formState: {errors},
    } = useForm<ResetFormValues & ChangeFormValues>({
        resolver: yupResolver(resetId ? resetPasswordSchema : newPasswordSchema),
    });

    const doRequest = async (values: any) => {
        try {
            setLoading(true);
            await fetch(resetId ? `/reset_password_requests/${resetId}` : '/reset_password_requests', {
                method: resetId ? 'PUT' : 'POST',
                body: JSON.stringify(values)
            })
            if (resetId) {
                alert(t('login.resetSuccess'));
                navigate('/login');
            } else {
                alert(t('login.sendSuccess'));
            }
        } catch (e) {
            alert(t('login.authservererror'))
        } finally {
            setLoading(false)
        }
    }

    return <>
        <Container style={{height: "100vh"}}>
            <Row className="align-items-center" style={{height: "100%"}}>
                <Col xs={12} sm={6} className="mx-auto">
                    <Image src={logo} fluid alt="Logo FSAS"/>
                </Col>
                {!resetId && <Col>
                    <h6 className="mb-4">{t('login.sendLinkTip')}</h6>
                    <Form onSubmit={handleSubmit(doRequest)}>
                        <Form.Group className="mb-3">
                            <Form.Label>{t("login.email")}</Form.Label>
                            <Form.Control {...register("email")} isInvalid={!!errors.email} disabled={isLoading}/>
                            <Form.Control.Feedback type="invalid">
                                {errors.email?.message}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Button disabled={isLoading} type="submit">{t('login.sendLink')}</Button>
                    </Form>
                </Col>}
                {resetId && <Col>
                    <h5 className="mb-4">{t('login.setPasswordTip')}</h5>
                    <Form onSubmit={handleSubmit(doRequest)}>
                        <Form.Group className="mb-3">
                            <Form.Label>{t("login.password")}</Form.Label>
                            <Form.Control {...register("newPassword")} type="password" isInvalid={!!errors.newPassword} disabled={isLoading}/>
                            <Form.Control.Feedback type="invalid">
                                {errors.newPassword?.message}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Button disabled={isLoading} type="submit">{t('login.changePassword')}</Button>
                    </Form>
                </Col>}
            </Row>
        </Container>

    </>;
}

export default PasswordReset;
