import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  ro: {
    translation: {
      submitQuestion: "Depui o cerere nouă?",
      createAccountHint: 'Dă click pe butonul "Creează-ți cont" pentru a începe.',
      slotcount: "{{count}} loc(uri)",
      slotcount_other: "{{count}} locuri",
      settingsLoadError: "A apărut o eroare la încărcarea datelor. Te rugăm să reîncarci pagina, iar dacă eroarea persistă contactează Comisia de Cazări.",
      title: "Cazare în cămin",
      settings: {
        canPostRequest: "Permite crearea cererilor noi",
        usersCanEdit: "Permite editarea cererilor",
        showRoom: "Permite studenților să își vadă camera",
        canConfirm: "Poate confirma/seta cererea în așteptare/redistribuire (este înainte de redistribuiri)",
        changeError: "A apărut o eroare în schimbarea setării, te rugăm să încerci din nou.",
        error: "A apărut o eroare în descărcarea setărilor, te rugăm să încerci din nou.",
      },
      nav: {
        login: "Autentificare",
        signup: "Înregistrare",
        logout: "Delogare",
        viewrequests: "Vezi cereri",
        settings: "Setări",
        dorms: "Cămine",
        repartitions: "Repartizări automate"
      },
      login: {
        anunt: "Pentru a vă depune cererea, trebuie să vă creați un cont pe platformă. Cei care au avut un cont în anii anteriori trebuie să işi creeze un cont nou.",
        email: "Adresă de mail",
        password: "Parolă",
        signin: "Intră",
        signup: "Creează-ți cont",
        validemail: "Te rugăm să verifici adresa de mail și să o corectezi.",
        provideemail: "Te rugăm să introduci o adresă de mail.",
        tooshortpassword: "Asigură-te că parola ta are cel puțin 6 caractere.",
        providepassword: "Te rugăm să introduci o parolă.",
        authusererror:
          "Te rugăm să verifici din nou adresa de mail și parola și să încerci din nou.",
        authservererror:
          "A apărut o eroare internă. Te rugăm să încerci din nou și dacă problema persistă să ne trimiți un email la cazari@as-sas.ro.",
        sendLink: "Trimite link-ul",
        sendLinkTip: "Dacă adresa de mail pe care o vei introduce este corectă, vei primi pe mail un link de resetare. Intră pe acel link și îți vei putea alege altă parolă.",
        setPasswordTip: "Introdu noua ta parolă în câmpul de mai jos.",
        changePassword: "Schimbă parola",
        sendSuccess: "Am trimis link-ul pe mail, te rugăm să intri pe el. Te rugăm să verifici și folderul Spam/Junk în cazul în care nu îl vezi.",
        resetSuccess: "Parola ta a fost schimbată cu success. O poți folosi să te loghezi în platformă.",
        forgotPassword: "Ai uitat parola?"
      },
      register: {
        invalidname: "Te rugăm să verifici din nou numele.",
        providename: "Te rugăm să îți introduci numele.",
        actIdentitateHint:
          "Seria și numărul buletinului trebuie scrise sub forma AB123456, unde AB este seria iar 123456 este numărul.",
        invalidact:
          "Te rugăm să introduci fie seria și numărul buletinului fie numărul pașaportului în cazul în care nu ai cetățenie română.",
        provideact:
          "Te rugăm să introduci fie seria și numărul buletinului fie numărul pașaportului în cazul în care nu ai cetățenie română.",
        invalidphone: "Te rugăm să verifici din nou numărul de telefon.",
        invalidcnp: "Te rugăm să verifici din nou CNP-ul.",
        providephone: "Te rugăm să introduci numărul tău de telefon.",
        firstname: "Prenume complet",
        lastname: "Nume de familie",
        cnp: "CNP",
        actIdentitate:
          "Seria și numărul buletinului (numărul pașaportului în cazul în care nu ai cetățenie română)",
        phoneNumber: "Numărul de telefon",
        submit: "Înregistrează-te",
        servererror:
          "A apărut o eroare internă. Te rugăm să încerci din nou și dacă problema persistă să ne trimiți un email la cazari@as-sas.ro.",
        usererror:
          "A apărut o problemă cu datele completate. Te rugăm să te asiguri că le-ai completat bine.",
        existingmailerror: "Adresa de mail este folosită la alt cont!",
        existingcnperror: "CNP-ul dat este asociat altui cont!",
      },
      request: {
        download: "Descarcă cererea",
        inRedistriubtion: "Confirmat pentru redistribuiri",
        inWaiting: "Confirmat drept cerere în așteptare",
        confirmed: "Loc confirmat",
        noRepartition: "Din păcate nu ai prins loc în cămin. Te rugăm să te adresezi Comisiei Centrale de Cazare a Direcției de Cămine/Cantine în cazul în care vrei să aplici la următoarea rundă de distribuiri.",
        applyRedistribution: "Aplică pentru redistribuiri",
        confirm: "Confirmă",
        applyWaiting: "Pune cererea în așteptare (cu eventualitatea de a primi alt loc)",
        creationDisabled: "Trimiterea de cereri noi a fost dezactivată. Dacă ceva este în neregulă, te rugăm să te adresezi Comisiei de Cazări, ale cărei date de contact le găsești pe site-ul facultății.",
        formError:
          "A apărut o eroare la încărcarea formularului. Te rugăm să încerci din nou.",
        loading: "Încărcare...",
        dormError:
          "A apărut o eroare la încărcarea căminelor. Te rugăm să dai refresh la pagină.",
        dormLoading: "Încărcare cămine...",
        readdDorm: "Adaugă",
        deleteDorm: "Șterge",
        previous: "Înapoi",
        next: "Înainte",
        step1: "Detalii personale",
        step2: "Situația școlară",
        step3: "Cazuri sociale",
        step4: "Loc în cămin",
        step5: "Diverse",
        // Step 1
        modul: "Modul",
        modulHint: "Dacă ești la master sau doctorat lasă câmpul necompletat",
        modulValue: {
          socio: "Sociologie",
          as: "Asistență Socială",
        },
        specializare: "Specializare",
        specValue: {
          socio: {
            socio: "Sociologie",
            ru: "Resurse Umane",
            antro: "Antropologie",
            socioen: "Sociologie în engleză"
          },
        },
        birthday: "Data nașterii",
        birthplace: "Locul nașterii",
        fathername: "Prenumele tatălui",
        mothername: "Prenumele mamei",
        address: "Adresa de domiciliu (conform buletinului: judet, localitate/comuna, sat, strada, nr, scara, bloc, apartament)",
        gender: "Sex",
        male: "Masculin",
        female: "Feminin",
        // Step 2
        year: "An de studiu",
        yearOne: "Anul I",
        yearTwo: "Anul II",
        yearThree: "Anul III",
        bigmodul: "Program de studiu",
        licenta: "Licență",
        master: "Master",
        doctorat: "Doctorat",
        restanteHint: "Ai avut restanțe în ultimul an universitar?",
        restante: "Câte restanțe ai avut?",
        media: "Care este media cu care ai absolvit ultimul an universitar / media de admitere (licență)",
        mediaHint:
          "Dacă intri în primul an la facultate (licență sau master), introdu media de admitere.",
        mediaDep1:
          "Care este nota ta la proba de limba română la BAC (licență) sau media de absolvire (master)?",
        mediaDep1Hint: "Dacă nu intri în anul I, lasă câmpul necompletat.",
        mediaDep2: "Medie departajare 2",
        mediaDep3: "Medie departajare 3",
        // Step 3
        socialCase: "În care dintre următoarele?",
        orphanOneParent: "Sunt orfan de un părinte",
        orphanBothParents: "Sunt orfan de ambii părinţi",
        plasament: "Sunt din centrul de plasament sau în plasament familial",
        handicap: "Am un handicap grav",
        otherSocialSituation: "Altă situaţie socială gravă/deosebită",
        socialCaseHint: "Te încadrezi într-o situație de caz social?",
        socialCaseProof: "Te rugăm să încarci documentele doveditoare.",
        socialCaseObs: "Alte observații",
        // Step 4
        roommateCnpEnter: "Dorești să ai un coleg anume de cameră?",
        roommateCnp:
          "Introdu CNP-ul său aici. Cererea ta va fi luată în calcul doar dacă și el/ea introudce CNP-ul tău!",
        continuityRoomNumberHint:
          "Dorești să îți păstrezi locul în același cămin (continuitate)?",
        continuityRoomNumber: "Introdu numărul camerei în care ai stat.",
        continuityRoomProof:
          "Încarcă un document doveditor (contract de cazare/decazare).",
        camin: "Introdu ordinea căminelor în care dorești să fii cazat(ă).",
        grozavestiA: "Grozăvești A",
        grozavestiB: "Grozăvești B",
        grozavestiA1: "Grozăvești A1",
        grozavestiC: "Grozăvești C",
        grozavestiD: "Grozăvești D",
        kogalniceanuA: "M. Kogălniceanu A",
        kogalniceanuB: "M. Kogălniceanu B",
        gaudeamus: "Gaudeamus",
        poligrafie: "Poligrafie",
        pallady1: "Theodor Pallady 1",
        pallady2: "Theodor Pallady 2",
        stoian: "Stoian Militaru",
        fundeni: "Fundeni",
        leu: "Leu C",
        selectDorms:
          'Te rugăm alege căminele în care ai dori să fii cazat(ă) și ordonează-le în funcție de preferințe prin tragere. Dacă nu vrei să fii cazat deloc într-un cămin poți apăsa pe butonul "Șterge" de lângă acesta. Dacă ești pe telefon, ține apăsat pe cămin două secunde și îl vei putea trage.',
        // Step 5
        parinteProf: "Ai vreunul dintre părinți cadru didactic?",
        yes: "Da",
        no: "Nu",
        bursa: "Ai beneficiat de bursă anul trecut?",
        bursaMerit: "Bursă de merit",
        bursaSociala: "Bursă socială",
        bursaErasmus: "Bursă Erasmus",
        selectOption: "Alege o opțiune",
        send: "Trimite",
        pfp: "Te rugăm să încarci o poză cu tine care va fi folosită pentru cerere.",
        required: "Acest câmp e obligatoriu!",
        requestError:
          "A apărut o eroare la încărcarea formularului. Te rugăm să încerci din nou.",
        congrats:
          "Felicitări, cererea ta a fost trimisă! Vei fi contactat pe mail după repartizări pentru a confirma locul sau a aplica la redistribuiri.",
      },
      view: {
        error:
          "A apărut o eroare la încărcarea cererii. Te rugăm să încerci din nou.",
        loading: "Încarc cererea...",
        edit: "Modifică",
        save: "Salvează",
        add: "Adaugă",
        cancel: "Anulează",
      },
      comisie: {
        round: {
          newRepartition: "Repartizare nouă",
          willShowUp: "Selectează una dintre runde și studenții care au fost redistribuiți în această rundă vor apărea aici.",
          error: "A apărut o eroare la redistribuiri."
        },
        requests: {
          openFilters: "Schimbă tabelul",
          show: "Alege opțiunile",
          getError: "A apărut o eroare în obținerea întrebărilor. Te rugăm să încerci din nou.",
          view: "Vezi cererea",
          close: "Închide",
          check: "Verifică",
          email: "Email",
          removeCheck: "Anulează verificarea",
          checkedBy: "Verificat de:",
          checkSuccess: "Verificat cu succes!",
          checkError: "A apărut o eroare. Te rugăm să încerci din nou.",
          deleteError: "A apărut o eroare. Te rugăm să încerci din nou.",
          delete: "Șterge cererea",
          exportExcel: "Exportă Excel",
          exportError: "A apărut o eroare la export. Te rugăm să încerci din nou."
        },
        request: {
          status: {
            waiting: "În așteptare",
            confirmed: "Confirmată",
            redistribution: "Redistribuire"
          },
          modul: "Modul",
          modulValue: {
            socio: "Sociologie",
            as: "Asistență Socială",
          },
          specializare: "Specializare",
          specValue: {
            socio: {
              socio: "Sociologie",
              ru: "Resurse Umane",
              antro: "Antropologie",
            },
          },
          birthday: "Data nașterii",
          birthplace: "Locul nașterii",
          fathername: "Prenumele tatălui",
          mothername: "Prenumele mamei",
          address: "Adresa domiciliu",
          gender: "Sex",
          male: "Masculin",
          female: "Feminin",
          year: "An de studiu",
          yearOne: "Anul I",
          yearTwo: "Anul II",
          yearThree: "Anul III",
          bigmodul: "Program de studiu",
          licenta: "Licență",
          master: "Master",
          doctorat: "Doctorat",
          socialCase: "Tip caz social",
          orphanOneParent: "Orfan de un părinte",
          orphanBothParents: "Orfan de ambii părinţi",
          plasament: "Plasament",
          handicap: "Handicap grav",
          otherSocialSituation: "Altă situaţie",
          socialCaseProof: "Documente caz social",
          socialCaseObs: "Alte observații",
          roommateCnp:
              "CNP coleg",
          continuityRoomNumber: "Cameră continuitate",
          continuityRoomProof:
              "Documente continuitate",
          restanteHint: "Are restanțe",
          restante: "Câte restanțe",
          media: "Medie",
          mediaDep1:
              "Medie departajare 1",
          mediaDep2: "Medie departajare 2",
          mediaDep3: "Medie departajare 3",
          camin: "Cămine",
          parinteProf: "Părinte prof.",
          yes: "Da",
          no: "Nu",
          bursa: "Tip bursă",
          bursaMerit: "Bursă de merit",
          bursaSociala: "Bursă socială",
          bursaErasmus: "Bursă Erasmus",
          selectOption: "Alege o opțiune",
          pfp: "Poză profil",
          required: "Acest câmp e obligatoriu!",
        },
        register: {
          firstname: "Prenume",
          lastname: "Nume de familie",
          email: "Email",
          cnp: "CNP",
          actIdentitate:
              "Act identitate",
          phoneNumber: "Telefon",
          status: "Status"
        },

        dorm: {
          distributionRound: "Rundă repartizări automate",
          submit: "Trimite",
          place: "Loc în cămin",
          block: "Blochează",
          unblock: "Deblochează",
          dormField: "Cămin",
          roomField: "Cameră",
          willShowUp: "Selectează un cămin din meniul din partea stângă și camerele vor apărea aici.",
          addRoom: "Adaugă camere",
          noSlots: "Asigură-te cå ai trecut numărul de locuri corect.",
          canOnlyIterateRooms: "Nu ai introdus un interval valid. Dacă numărul camerei conține și litere, lasă al doilea rând necompletat.",
          startRoom: "Cameră (de început)",
          endRoom: "Cameră de final",
          slotsField: "Locuri",
          roomRangeOnlyNumbers: "Poți folosi cele două câmpuri de mai jos pentru a adăuga automat camerele care sunt în interval (ex: 31-45). Atenție, intervalul funcționează doar pentru camere cu număr fără litere (funcționează de la 31 la 45, dar nu de la 31A la 31H)! Dacă vrei să adaugi o singură cameră, lasă câmpul Cameră de final necompletat.",
          blockerError: "Această cameră are deja un coleg de un alt gen. Sigur vrei să cazezi studentul aici?",
          cazareError: "A apărut o eroare la cazare, te rugăm să încerci din nou.",
          addStudent: "Cazează un student"
        }
      },
    },
  },
  en: {
    translation: {
      submitQuestion: "Submitting a new request?",
      createAccountHint: 'Please click on the "Create Account" button to get started.',
      slotcount: "{{count}} slot(s)",
      slotcount_other: "{{count}} slots",
      settingsLoadError: "An error occurred when loading data. Please refresh the page, and if the error persists contact the accommodation commission.",
      title: "Accommodation in the housing",
      settings: {
        canPostRequest: "Allows the creation of new requests",
        usersCanEdit: "Allows to edit requests",
        showRoom: "Allow students to check their room",
        canConfirm: "Can confirm/set the requesting application/redistribution (it is before redistribution)",
        changeError: "An error has occurred in changing the setting, please try again.",
        error: "An error has occurred in downloading settings, please try again.",
      },
      nav: {
        login: "Login",
        signup: "Register",
        logout: "Disconnect",
        viewrequests: "See requests",
        settings: "Settings",
        dorms: "Dorms",
        repartitions: "Automatic distributions"
      },
      login: {
        anunt: "To submit your request, you must create an account on the platform. Those who had an account in previous years must create a new account.",
        email: "E-mail address",
        password: "Password",
        signin: "Sign In",
        signup: "Create Account",
        validemail: "Please check the email address for mistakes.",
        provideemail: "Please enter an email address.",
        tooshortpassword: "Make sure your password has at least 6 characters.",
        providepassword: "Please enter a password.",
        authusererror:
          "Please check the email address and password and try again.",
        authservererror:
          "An internal error has occurred. Please try again and if the problem persists, send us an email at cazari@as-sas.ro.",
        sendLink: "Send the link",
        sendLinkTip: "If the email address you enter is correct, you will receive a password reset link on your email address. Click the link and you will be able to change the password.",
        setPasswordTip: "Enter your new password in the field below.",
        changePassword: "Change the password",
        sendSuccess: "We've sent the link by email, please click it. Please check the Spam/Junk folder if you don't see it.",
        resetSuccess: "Your password has been successfully changed. You can use it to log in on the site.",
        forgotPassword: "Forgot password?"
      },
      register: {
        invalidname: "Please check the name again.",
        providename: "Please enter your name.",
        actIdentitateHint:
          "The series and number of the ID card should be written in the form of AB123456, where AB is the series and 123456 is the number.",
        invalidact:
          "Please enter either the series and number of the ID card or the passport number if you do not have Romanian citizenship.",
        provideact:
          "Please enter either the series and number of the ID card or the passport number if you do not have Romanian citizenship.",
        invalidphone: "Please check your phone number again.",
        invalidcnp: "Please check your Personal Identification Number again.",
        providephone: "Please enter your phone number.",
        firstname: "First name",
        lastname: "Last name",
        cnp: "PIN (CNP)",
        actIdentitate:
          "The series and the number of the ID card (the passport number if you do not have Romanian citizenship)",
        phoneNumber: "Phone number",
        submit: "Submit",
        servererror:
          "An internal error has occurred. Please try again and if the problem persists, send us an email at cazari@as-sas.ro.",
        usererror:
          "A problem has occurred with the completed data. Please check with the completed data",
        existingmailerror: "The email address is used for another account!",
        existingcnperror: "The given Personal Identification Number is associated with another account!",
      },
      request: {
        download: "Download the request",
        inRedistriubtion: "Confirmed for redistributions",
        inWaiting: "Confirmed as a standing request",
        confirmed: "Confirmed",
        noRepartition: "Unfortunately you didn't take place in the housing.Please contact the Central Commission for the Accommodation of the Directorate of Housing if you want to apply to the next round of distribution.",
        applyRedistribution: "Apply for redistributions",
        confirm: "Confirm",
        applyWaiting: "Place the request on waiting queue",
        creationDisabled: "New requests can't be accepted for the moment. If something is wrong, please contact the accommodation commission, whose contact details you can find on the faculty website.",
        formError:
          "An error occurred when loading the form.Please try again.",
        loading: "Loading...",
        dormError:
          "An error occurred when loading dorms. Please refresh the page.",
        dormLoading: "Loading dorms...",
        readdDorm: "Add",
        deleteDorm: "Delete",
        previous: "Back",
        next: "Next",
        step1: "Personal details",
        step2: "Academic record",
        step3: "Social cases",
        step4: "Housing",
        step5: "Misc",
        modulHint: "If your study program is Master's or PHD leave this blank",
        modul: "Modules",
        modulValue: {
          socio: "Sociology",
          as: "Social work",
        },
        specializare: "Specialization",
        specValue: {
          socio: {
            socio: "Sociology",
            ru: "Human Resources",
            antro: "Anthropology",
            socioen: "Sociology in english"
          },
        },
        birthday: "Date of birth",
        birthplace: "Birthplace",
        fathername: "Father's surname",
        mothername: "Mother's surname",
        address: "Home address (ID card)",
        gender: "Gender",
        male: "Male",
        female: "Female",
        year: "Year of study (That you're going to begin)",
        yearOne: "1st year",
        yearTwo: "2nd year",
        yearThree: "3rd year",
        bigmodul: "Study program",
        licenta: "Bachelor's Degree",
        master: "Master's Degree",
        doctorat: "Doctorate",
        socialCase: "In which of the following?",
        orphanOneParent: "I am an orphan by a parent",
        orphanBothParents: "I am an orphan of both parents",
        plasament: "I am from the placement center or in family placement",
        handicap: "I have a handicap",
        otherSocialSituation: "Another serious/special social situation",
        socialCaseHint: "Do you have a social case situation?",
        socialCaseProof: "Please upload the proof documents.",
        socialCaseObs: "Other observations",
        roommateCnpEnter: "Do you want a specific room colleague?",
        roommateCnp:
          "Introduce his Personal Identification Number here.Your request will only be taken into account if he/she inserts your Personal Identification Number!",
        continuityRoomNumberHint:
          "Do you want to keep your place in the same dorm (continuity)?",
        continuityRoomNumber: "Introduce the number of the dorm you stayed in.",
        continuityRoomProof:
          "Load a proof document (accommodation/decay contract).",
        restanteHint: "You had any exam retakes in the last year academic?",
        restante: "How many exam retakes did you have?",
        media: "What is the grade with which you graduated the last academic year with / admission average (Bachelor's degree)",
        mediaHint:
          "If it's your first year in college, insert the grade of admission.",
        mediaDep1:
          "What is your grade in the Romanian language test at BAC (Bachelor's Degree) or graduation average (Master's Degree)?",
        mediaDep1Hint: "If it's not your first year in college, leave the field blank.",
        mediaDep2: "Average departure 2",
        mediaDep3: "Average departure 3",
        camin: "Enter the order of the homes in which you want to be accommodated.",
        grozavestiA: "Grozăvești A",
        grozavestiB: "Grozăvești B",
        grozavestiA1: "Grozăvești A1",
        grozavestiC: "Grozăvești C",
        grozavestiD: "Grozăvești D",
        kogalniceanuA: "M. Kogălniceanu A",
        kogalniceanuB: "M. Kogălniceanu B",
        gaudeamus: "Gaudeamus",
        poligrafie: "Poligrafie",
        pallady1: "Theodor Pallady 1",
        pallady2: "Theodor Pallady 2",
        stoian: "Stoian Militaru",
        fundeni: "Fundeni",
        leu: "Leu C",
        parinteProf: "Are any of your parents in the academic environment?",
        yes: "Yes",
        no: "No",
        bursa: "Have you benefited from the scholarship last year?",
        bursaMerit: "Scholarship",
        bursaSociala: "Social scholarship",
        bursaErasmus: "Erasmus scholarship",
        selectOption: "Choose an option",
        selectDorms:
          'Please choose the homes in which you would like to be accommodated and order them according to the preferences by dragging. If you do not want to be accommodated at all in a home you can press the "Delete" button next to it. If you are on the phone, hold on to the home for two seconds and you will be able to drag it.',
        send: "Send",
        pfp: "Please upload a profile picture with you that will be used for the request.",
        required: "This field is required!",
        requestError:
          "An error occurred when uploading the form. Please try again.",
        congrats:
          "Congratulations, your request has been sent! You will be contacted by email after distributions to confirm your place or apply to redistributions.",
      },
      view: {
        error:
          "An error occurred when loading the request. Please try again.",
        loading: "Loading request...",
        edit: "Edit",
        save: "Save",
        add: "Add",
        cancel: "Cancel",
      },
      comisie: {
        round: {
          newRepartition: "Repartizare nouă",
          willShowUp: "Selectează una dintre runde și studenții care au fost redistribuiți în această rundă vor apărea aici.",
          error: "A apărut o eroare la redistribuiri."
        },
        requests: {
          openFilters: "Schimbă tabelul",
          show: "Alege opțiunile",
          getError: "A apărut o eroare în obținerea întrebărilor. Te rugăm să încerci din nou.",
          view: "See Request",
          close: "Închide",
          check: "Verifică",
          email: "Email",
          removeCheck: "Anulează verificarea",
          checkedBy: "Verificat de:",
          checkSuccess: "Verificat cu succes!",
          checkError: "A apărut o eroare. Te rugăm să încerci din nou.",
          deleteError: "A apărut o eroare. Te rugăm să încerci din nou.",
          delete: "Șterge cererea",
          exportExcel: "Exportă Excel",
          exportError: "A apărut o eroare la export. Te rugăm să încerci din nou."
        },
        request: {
          status: {
            waiting: "În așteptare",
            confirmed: "Confirmată",
            redistribution: "Redistribuire"
          },
          modul: "Modul",
          modulValue: {
            socio: "Sociologie",
            as: "Asistență Socială",
          },
          specializare: "Specializare",
          specValue: {
            socio: {
              socio: "Sociologie",
              ru: "Resurse Umane",
              antro: "Antropologie",
            },
          },
          birthday: "Data nașterii",
          birthplace: "Locul nașterii",
          fathername: "Prenumele tatălui",
          mothername: "Prenumele mamei",
          address: "Adresa domiciliu",
          gender: "Sex",
          male: "Masculin",
          female: "Feminin",
          year: "An de studiu (pe care urmează să-l incepi)",
          yearOne: "Anul I",
          yearTwo: "Anul II",
          yearThree: "Anul III",
          bigmodul: "Program de studiu",
          licenta: "Licență",
          master: "Master",
          doctorat: "Doctorat",
          socialCase: "Tip caz social",
          orphanOneParent: "Orfan de un părinte",
          orphanBothParents: "Orfan de ambii părinţi",
          plasament: "Plasament",
          handicap: "Handicap grav",
          otherSocialSituation: "Altă situaţie",
          socialCaseProof: "Documente caz social",
          socialCaseObs: "Alte observații",
          roommateCnp:
              "CNP coleg",
          continuityRoomNumber: "Cameră continuitate",
          continuityRoomProof:
              "Documente continuitate",
          restanteHint: "Are restanțe",
          restante: "Câte restanțe",
          media: "Medie",
          mediaDep1:
              "Medie departajare 1",
          mediaDep2: "Medie departajare 2",
          mediaDep3: "Medie departajare 3",
          camin: "Cămine",
          parinteProf: "Părinte prof.",
          yes: "Da",
          no: "Nu",
          bursa: "Tip bursă",
          bursaMerit: "Bursă de merit",
          bursaSociala: "Bursă socială",
          bursaErasmus: "Bursă Erasmus",
          selectOption: "Alege o opțiune",
          pfp: "Poză profil",
          required: "Acest câmp e obligatoriu!",
        },
        register: {
          firstname: "Prenume",
          lastname: "Nume de familie",
          email: "Email",
          cnp: "CNP",
          actIdentitate:
              "Act identitate",
          phoneNumber: "Telefon",
          status: "Status"
        },

        dorm: {
          distributionRound: "Rundă repartizări automate",
          submit: "Trimite",
          place: "Loc în cămin",
          block: "Blochează",
          unblock: "Deblochează",
          dormField: "Cămin",
          roomField: "Cameră",
          willShowUp: "Selectează un cămin din meniul din partea stângă și camerele vor apărea aici.",
          addRoom: "Adaugă camere",
          noSlots: "Asigură-te cå ai trecut numărul de locuri corect.",
          canOnlyIterateRooms: "Nu ai introdus un interval valid. Dacă numărul camerei conține și litere, lasă al doilea rând necompletat.",
          startRoom: "Cameră (de început)",
          endRoom: "Cameră de final",
          slotsField: "Locuri",
          roomRangeOnlyNumbers: "Poți folosi cele două câmpuri de mai jos pentru a adăuga automat camerele care sunt în interval (ex: 31-45). Atenție, intervalul funcționează doar pentru camere cu număr fără litere (funcționează de la 31 la 45, dar nu de la 31A la 31H)! Dacă vrei să adaugi o singură cameră, lasă câmpul Cameră de final necompletat.",
          blockerError: "Această cameră are deja un coleg de un alt gen. Sigur vrei să cazezi studentul aici?",
          cazareError: "A apărut o eroare la cazare, te rugăm să încerci din nou.",
          addStudent: "Cazează un student"
        }
      },
    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "ro",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
