import {Answer, Request} from "../../models/Request";
import {jsPDF} from "jspdf";
import {Button} from "react-bootstrap";
import './LiberationSerif-Regular-normal'
import './LiberationSerif-Bold-bold'
import {useTranslation} from "react-i18next";
import signature from '../../assets/semnatura.png';

const DownloadRequest = (props: { request: Request, answers: { [qId: number]: Answer } }) => {
    const {request, answers} = props;
    const {t} = useTranslation();

    const downloadDoc = () => {
        const info = {
            birthday: new Date(answers[4].value).toLocaleDateString("ro-RO"),
            birthplace: answers[5].value,
            fatherName: answers[6].value,
            motherName: answers[7].value,
            address: answers[8].value,
            socialCase: answers[17] ? answers[17].value : undefined,
            restante: answers[12] ? answers[12].value : undefined,
            media: answers[13] ? answers[13].value : undefined,
            parinteProf: answers[25] ? answers[25].value : undefined,
            continuityRoom: answers[22] ? answers[22].value : undefined,
            dorms: JSON.parse(answers[24].value)
        }


        const doc = new jsPDF();
        doc.setFont("LiberationSerif-Bold", "bold")
        doc.setFontSize(20)
        doc.text("CERERE DE CAZARE", 10, 10);
        doc.text("ANUL UNIVERSITAR 2023-2024", 10, 20);

        doc.setFontSize(18)
        doc.text("I. DATE PERSONALE", 10, 40)

        doc.setFont("LiberationSerif-Regular", "normal")
        doc.setFontSize(12)
        doc.text(`Numele complet: ${request.student.lastName} ${request.student.firstName}`, 10, 50);
        doc.text(`Data nașterii: ${info.birthday}          Locul nașterii: ${info.birthplace}`, 10, 60)
        doc.text(`Prenumele tatălui: ${info.fatherName}          Prenumele mamei: ${info.motherName}`, 10, 70)
        doc.text(`Adresa completă: ${info.address}`, 10, 80);
        doc.text(`Telefon: ${request.student.phoneNumber}`, 10, 90);
        doc.text(`Adresa e-mail: ${request.student.email}`, 10, 100)
        doc.text(`C.I./Pașaport nr: ${request.student.actIdentitate}          CNP: ${request.student.cnp}`, 10, 110)

        doc.setFont("LiberationSerif-Bold","bold")
        doc.setFontSize(18)
        doc.text("II. DATE CU PRIVIRE LA STUDII", 10, 120);

        doc.setFont("LiberationSerif-Regular", "normal")
        doc.setFontSize(12)
        doc.text("Sunt student/ă la Facultatea de Sociologie și Asistență Socială, anul de studii 2023-2024", 10, 130);

        doc.setFont("LiberationSerif-Bold", "bold")
        doc.setFontSize(18)
        doc.text("III. DATE DESPRE CAZARE", 10, 140);

        doc.setFont("LiberationSerif-Regular", "normal")
        doc.setFontSize(12)
        if (info.continuityRoom) {
            doc.text("1. În anul universitar anterior, am domiciliat în căminele Universității din București:", 10, 150);
            doc.text(`Căminul ${t(info.dorms[0])}, camera ${info.continuityRoom}`, 10, 155)
        } else {
            doc.text("1. În anul universitar anterior, nu am beneficiat de cazare din partea Universității.", 10, 150);
        }

        doc.text(`2. Pentru anul universitar 2023-2024, solicit acordarea unui loc de cazare în căminul`, 10, 165);
        doc.text(`${t(request.repartitions[0].room?.dorm?.name ?? '')}, camera ${t(request.repartitions[0].room?.number ?? '')}`, 10, 170);

        let vedereText = '';
        if (info.restante) {
            vedereText += `${info.restante} restanțe`
        } else if (info.media) {
            vedereText += `medie ${info.media}`;
        }

        if (info.continuityRoom && request.repartitions[0].room?.dorm?.name === info.dorms[0]) {
            vedereText += ", continuitate";
        }

        if (info.parinteProf === "request.yes") {
            vedereText += ", copil de cadru didactic activ"
        }

        if (info.socialCase) {
            switch (info.socialCase) {
                case 'request.plasament':
                    vedereText += ", provin de la o casă de copii"
                    break;
                case 'request.orphanBothParents':
                    vedereText += ", orfan de ambii părinți"
                    break;
                default:
                    vedereText += ", situație socială gravă"
                    break;
            }
        }

        doc.text("Rog Comisia ca, la evaluarea cererii, să aibă în vedere următoarele: ", 10, 180);
        doc.text(vedereText, 10, 185);

        doc.setFontSize(10)
        doc.text("Declar că am luat la cunoștință despre Metodologia privind procesul de cazare a studenților Universității din București", 10, 195);
        doc.text("și că sunt de acord cu prevederile acesteia. Anexez la prezenta actele doveditoare (în copie) pentru situația socială.", 10, 200)

        doc.setFontSize(12)
        doc.text("Data:                                               Semnătura: ", 10, 210);

        doc.setFontSize(10)
        doc.text(`Subsemnatul/a ${request.student.lastName} ${request.student.firstName}, îmi exprim acordul cu privire la utilizarea și prelucrarea de către Universitatea din București,`, 10, 230);
        doc.text("a următoarelor date cu caracter personal: adresa, locul nașterii, CNP, data nașterii, CI serie și număr, Telefon, E-mail,", 10, 235);
        doc.text("în scopul efectuării tuturor operațiunilor legate de cazarea în căminele Universității din București.", 10, 240);

        doc.setFontSize(12)
        doc.text("Semnătura: ", 10, 250)

        doc.setFont("LiberationSerif-Bold", "bold")
        doc.setFontSize(18)
        doc.text("DECIZIA COMISIEI DE CAZARE:", 10, 260);

        doc.setFont("LiberationSerif-Regular", "normal")
        doc.setFontSize(12)
        doc.text(`Se acordă un loc de cazare în căminul ${t(request.repartitions[0].room?.dorm?.name ?? '')}, camera: ${request.repartitions[0].room?.number ?? ''}`, 10, 270);
        doc.text("Semnătura și ștampila Comisiei de Cazare", 10, 280);

        console.log(signature)
        doc.addImage(signature, 'PNG', 100, 270, 80, 30);

        doc.save("cerere.pdf")
    }

    return <Button onClick={downloadDoc}>{t('request.download')}</Button>;
}

export default DownloadRequest;