import { Container, Row, Col, Image } from "react-bootstrap";
import logo from '../assets/logo.webp';
import LoginForm from "../components/Login/LoginForm";
import i18next from "i18next";

const Login = () => {

  return <>
    <Container style={{ height: "100vh" }}>
      <Row>
        <h5 className="text-muted">{i18next.t<string>("login.anunt")}</h5>
      </Row>
      <Row className="align-items-center" style={{ height: "100%" }}>
        <Col xs={12} sm={6} className="mx-auto">
          <Image src={logo} fluid alt="Logo FSAS" />
        </Col>
        <Col>
          <LoginForm />
        </Col>
      </Row>
    </Container>

  </>;
}

export default Login;
