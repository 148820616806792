import {useCallback, useEffect, useState} from "react";
import {Alert, Button, Container, ListGroup, Stack} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import fetch from "../../fetch";
import create from "zustand";
import produce from "immer";
import {Answer, Request} from "../../models/Request";
import RequestDetailsListItem from "./RequestDetailsListItem";
import useGlobalStore from "../../globalState";
import {Question} from "../../models/Question";
import {captureException} from "@sentry/react";
import RepartitionDetails from "./RepartitionDetails";

interface ViewRequestState {
    requestDetails: Request | null;
    questions: { [stepNumber: number]: { [qId: number]: Question } };
    answers: { [qId: number]: Answer };
    fetchQuestions: () => void;
    fetchRequest: (rId: number | undefined) => void;
    setAnswerValue: (qId: number, value: any) => void;
}

export const useViewRequestStore = create<ViewRequestState>((set, get) => ({
    requestDetails: null,
    questions: {},
    answers: {},
    fetchQuestions: async () => {
        if (Object.keys(get().questions).length > 0) {
            // We have already downloaded the questions.
            return;
        }
        const isComisie = useGlobalStore.getState().hasPermission('ROLE_COMISIE');
        let qUrl = '/questions?active=true';
        if (!isComisie) {
            qUrl += "&comisieOnly=false";
        }
        try {
            const questionsResponse = await fetch(qUrl);
            const questionsObj = await questionsResponse.json();
            const finalQuestionsObj: { [stepNumber: number]: { [qId: number]: Question } } = {};
            for (const question of questionsObj) {
                if (!finalQuestionsObj[question.step]) {
                    finalQuestionsObj[question.step] = {};
                }
                finalQuestionsObj[question.step][question.id] = question;
            }
            set({questions: finalQuestionsObj});
        } catch (e) {
            //TODO: Smth
        }
    },
    fetchRequest: async (r) => {
        await get().fetchQuestions();
        const req = await fetch(
            r ? `/requests/${r}` : "/requests_me"
        );
        const res = await req.json();
        // Group by steps
        const ansObj: { [qId: number]: Answer } = {};
        for (const ans of res.answers) {
            const qParts = ans.question.split('/');
            const qId = parseInt(qParts[qParts.length - 1]);
            ansObj[qId] = ans;
        }
        delete res.answers;
        set({
            requestDetails: res,
            answers: ansObj
        })
    },
    setAnswerValue: (qId, obj) =>
        set(
            produce((state) => {
                if (!state.answers) {
                    return;
                }
                const answers = state.answers;
                answers[qId] = obj;
            })
        ),
}));

const RequestDetails = (props: { id?: number }) => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [checkLoading, setCheckLoading] = useState(false);
    const [error, setError] = useState(false);
    const request = useViewRequestStore((s) => s.requestDetails);
    const questions = useViewRequestStore((s) => s.questions);
    const answers = useViewRequestStore((s) => s.answers);
    const hasPermission = useGlobalStore(s => s.hasPermission);
    const settings = useGlobalStore(s => s.settings);

    const getRequest = useCallback(async () => {
        try {
            const fetchRequest = useViewRequestStore.getState().fetchRequest;
            await fetchRequest(props.id);
        } catch (e: any) {
            if (e.status === 404) {
                navigate("/request", {replace: true});
                return;
            }
            captureException(e);
            setError(true);
        } finally {
            setLoading(false);
        }
    }, [props.id, navigate]);

    useEffect(() => {
        getRequest();
    }, [getRequest]);

    const checkRequest = async (clear: boolean = false) => {
        setCheckLoading(true);
        try {
            await fetch(`/requests/${props.id}`, {
                method: "PUT",
                body: JSON.stringify({
                    checked: !clear,
                }),
            });

            getRequest();
        } catch (e) {
            alert(t("comisie.requests.checkError"));
        } finally {
            setCheckLoading(false);
        }
    };

    if (loading) {
        return <p>{t("view.loading")}</p>;
    }

    if (error || !request) {
        return (
            <Container>
                <Alert variant="danger">{t("view.error")}</Alert>
            </Container>
        );
    }

    return (
        <Container>
            {hasPermission('ROLE_COMISIE') && request?.checkedBy && (
                <h5>
                    {t("comisie.requests.checkedBy") +
                        ` ${request.checkedBy.firstName} ${request.checkedBy.lastName}`}
                </h5>
            )}

            {(settings["showRoom"] || hasPermission('ROLE_COMISIE')) && <RepartitionDetails refresh={getRequest}/>}

            <ListGroup as="ol" numbered>
                {Object.keys(questions).map((step) => {
                    return (
                        <div key={`step-${step}`}>
                            <h2 className="mt-3" style={{textAlign: "start"}}>
                                {t(`request.step${step}`)}
                            </h2>
                            {Object.entries(questions[parseInt(step)]).map(([qId, q]) => (
                                <RequestDetailsListItem
                                    question={q}
                                    answer={answers[parseInt(qId)]}
                                    request={request}
                                    key={qId + "ans"}/>
                            ))}
                        </div>
                    );
                })}
            </ListGroup>
            <br/>
            <Stack direction="horizontal">
                {hasPermission('ROLE_ADMIN') && request.checkedBy && (
                    <Button
                        variant="danger"
                        onClick={() => checkRequest(true)}
                        disabled={checkLoading}
                    >
                        {t("comisie.requests.removeCheck")}
                    </Button>
                )}
                {hasPermission('ROLE_COMISIE') && !request.checkedBy && (
                    <Button
                        variant="success"
                        onClick={() => checkRequest()}
                        disabled={checkLoading}
                    >
                        {t("comisie.requests.check")}
                    </Button>
                )}
            </Stack>
        </Container>
    );
};

export default RequestDetails;
