import { Dorm } from "../../models/Dorm";
import DormListItem from "./DormListItem";
import { ReactSortable } from "react-sortablejs";
const DormList = (props: {
  id: string;
  dorms: Dorm[];
  setDorms: (dorms: Dorm[]) => void;
}) => {
  return (
    <ReactSortable list={props.dorms} delayOnTouchOnly={true} delay={500} setList={props.setDorms}>
      {props.dorms.map((dorm) => (
        <DormListItem dorm={dorm} key={dorm.id} />
      ))}
    </ReactSortable>
  );
};

export default DormList;
