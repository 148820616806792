import { ReactElement, useEffect, useState } from "react";
import {
  Button,
  Form,
  ToggleButton,
  ToggleButtonGroup,
  Stack,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import fetch from "../fetch";
import shallow from "zustand/shallow";
import { useRequestStore, useAnswerStore, useDormStore } from "../globalState";
import { Question } from "../models/Question";
import { useForm } from "react-hook-form";
import DormPicker from "./Request/DormPicker";
import S3 from "react-aws-s3-typescript";
import { useNavigate } from "react-router-dom";
import {captureException} from "@sentry/react";
// TODO: Move to a better place
const S3_BUCKET = "cazarebucket";
const REGION = "eu-north-1";
const ACCESS_KEY = "AKIA26YJXKMUBKAII4YV";
const SECRET_ACCESS_KEY = "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";
export const s3Config = {
  bucketName: S3_BUCKET,
  region: REGION,
  accessKeyId: ACCESS_KEY,
  secretAccessKey: SECRET_ACCESS_KEY,
};

const StepX = (props: { step: number }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const answers = useAnswerStore((s) => s.answers);
  const setAnswers = useAnswerStore((s) => s.setAnswers);

  const {
    register,
    handleSubmit,
    watch,
    getValues,
    formState: { errors },
  } = useForm<any>({ defaultValues: answers });
  // Trigger component refresh on form change
  watch();
  const stepQuestions = useRequestStore((s) => s.stepQuestions, shallow);
  const setStepQuestions = useRequestStore((s) => s.setStepQuestions);
  const showQuestions = useRequestStore((s) => s.showQuestions);
  const setShowQuestions = useRequestStore((s) => s.setShowQuestion);
  const prevStep = useRequestStore((s) => s.prevStep);
  const nextStep = useRequestStore((s) => s.nextStep);

  const [getError, setGetError] = useState(false);
  useEffect(() => {
    const getStepQuestions = async () => {
      if (stepQuestions[props.step]) {
        return;
      }
      try {
        const questions = await fetch(
          process.env.REACT_APP_API_URL +
            `/questions?active=true&comisieOnly=false&exists[showCondition]=false&order[showOrder]=asc&step=${
              props.step + 1
            }`
        );
        const qBody = await questions.json();
        setStepQuestions(props.step, qBody);
      } catch (e) {
        // TODO: Report
        console.error(e);
        setGetError(false);
      }
    };

    getStepQuestions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.step]);

  const uploadRequest = async (v: any) => {
    try {
      if (loading) {
        return;
      }
      setLoading(true);
      let answersToUpload = v;
      const s3 = new S3(s3Config);
      for (const [key, value] of Object.entries(v)) {
        if (value === t("request.selectOption")) {
          delete answersToUpload[key];
          continue;
        }

        if (value instanceof FileList) {
          if (value.length > 0) {
            let setStr = "";
            for (let i = 0; i < value.length; i++) {
              const res = await s3.uploadFile(value[i]);
              setStr += res.location;
              setStr += "|";
            }
            // Remove last |
            setStr = setStr.slice(0, -1);
            answersToUpload[key] = setStr;
          } else {
            delete answersToUpload[key];
            continue;
          }
        }

        answersToUpload[key] = {
          question: `/questions/${key}`,
          value: answersToUpload[key],
        };
      }
      const dormOptions = useDormStore.getState().dorms;
      const removedDorms = useDormStore.getState().deletedDormIds;
      // Id intrebare camine
      // TODO: Change this
      answersToUpload[24] = {
        question: `/questions/${24}`,
        value: JSON.stringify(
          dormOptions.filter((d) => !removedDorms[d.id]).map((d) => d.name)
        ),
      };

      await fetch(process.env.REACT_APP_API_URL + "/requests", {
        method: "POST",
        body: JSON.stringify({
          answers: answersToUpload,
        }),
      });
      navigate("/success?isnew=true");
    } catch (e) {
      captureException(e);
      setLoading(false);
      alert(t("request.requestError"));
    }
  };

  const submit = (v: any) => {
    if (props.step === 4) {
      uploadRequest(v);
      // Submit
      return;
    }

    const realAnswers: any = {};
    for (const [key, value] of Object.entries(v)) {
      if (value === t("request.selectOption")) {
        continue;
      }
      realAnswers[key] = value;
    }

    setAnswers(realAnswers);
    nextStep();
  };

  if (getError) {
    return <p>{t("request.formError")}</p>;
  }

  if (!stepQuestions[props.step]) {
    return <p>{t("request.loading")}</p>;
  }

  const renderQuestion = (question: Question): ReactElement[] => {
    const questionParts: any[] = [];
    if (question.canBeNullAnswer) {
      questionParts.push(
        <>
          <Form.Label>{t(question.hint ?? "")}</Form.Label>
          <ToggleButtonGroup
            className="ms-3"
            type="radio"
            name={question.id.toString()}
            value={showQuestions[question.id] ? 1 : 0}
            onChange={(v) => setShowQuestions(question.id, !!v)}
          >
            <ToggleButton
              type="radio"
              id={question.id.toString() + "y"}
              value={1}
            >
              {t("request.yes")}
            </ToggleButton>
            <ToggleButton
              type="radio"
              id={question.id.toString() + "n"}
              value={0}
            >
              {t("request.no")}
            </ToggleButton>
          </ToggleButtonGroup>
        </>
      );
    }
    if (question.canBeNullAnswer && !showQuestions[question.id]) {
      return questionParts;
    }
    switch (question.type) {
      case "dropdown":
        questionParts.push(
          <>
            <Form.Label>{t(question.text)}</Form.Label>
            <Form.Select
              aria-label={t(question.text)}
              aria-describedby={`${question.id}-hintcontrol`}
              isInvalid={!!errors[question.id]}
              className="mb-3"
              {...register(question.id.toString(), {
                required:
                  question.mandatory &&
                  (!question.canBeNullAnswer || showQuestions[question.id]),
              })}
            >
              <option>{t("request.selectOption")}</option>
              {question.properties.options.map((opt: string, index: number) => (
                <option value={opt} key={`question.id-${index}`}>
                  {t(opt)}
                </option>
              ))}
            </Form.Select>
            {!!question.hint && (
              <Form.Text id={`${question.id}-hintcontrol`} muted>
                {t(question.hint)}
              </Form.Text>
            )}

            <Form.Control.Feedback type="invalid">
              {errors[question.id]?.type === "required" &&
                t("request.required")}
            </Form.Control.Feedback>
          </>
        );
        break;
      case "text":
        questionParts.push(
          <>
            <Form.Label htmlFor={`${question.id}-control`}>
              {t(question.text)}
            </Form.Label>
            <Form.Control
              className="mb-3"
              id={`${question.id}-control`}
              isInvalid={!!errors[question.id]}
              aria-describedby={`${question.id}-hintcontrol`}
              as={question.properties?.long === true ? "textarea" : "input"}
              {...register(question.id.toString(), {
                required:
                  !!question.mandatory &&
                  (!question.canBeNullAnswer || showQuestions[question.id]),
              })}
            />
            {!!question.hint && !question.canBeNullAnswer && (
              <Form.Text id={`${question.id}-hintcontrol`} muted>
                {t(question.hint)}
              </Form.Text>
            )}
            <Form.Control.Feedback type="invalid">
              {errors[question.id]?.type === "required" &&
                t("request.required")}
            </Form.Control.Feedback>
          </>
        );
        break;
      case "date":
        questionParts.push(
          <>
            <Form.Label htmlFor={`${question.id}-control`}>
              {t(question.text)}
            </Form.Label>
            <Form.Control
              type="date"
              className="mb-3"
              isInvalid={!!errors[question.id]}
              id={`${question.id}-control`}
              aria-describedby={`${question.id}-hintcontrol`}
              {...register(question.id.toString(), {
                required:
                  question.mandatory &&
                  (!question.canBeNullAnswer || showQuestions[question.id]),
              })}
            />
            {!!question.hint && !question.canBeNullAnswer && (
              <Form.Text id={`${question.id}-hintcontrol`} muted>
                {t(question.hint)}
              </Form.Text>
            )}

            <Form.Control.Feedback type="invalid">
              {errors[question.id]?.type === "required" &&
                t("request.required")}
            </Form.Control.Feedback>
          </>
        );
        break;
      case "file":
        questionParts.push(
          <>
            <Form.Label htmlFor={`${question.id}-control`}>
              {t(question.text)}
            </Form.Label>
            <Form.Control
              type="file"
              className="mb-3"
              isInvalid={!!errors[question.id]}
              multiple={!question.properties?.single}
              id={`${question.id}-control`}
              aria-describedby={`${question.id}-hintcontrol`}
              {...register(question.id.toString(), {
                required:
                  question.mandatory &&
                  (!question.canBeNullAnswer || showQuestions[question.id]),
              })}
            />
            {!!question.hint && !question.canBeNullAnswer && (
              <Form.Text id={`${question.id}-hintcontrol`} muted>
                {t(question.hint)}
              </Form.Text>
            )}

            <Form.Control.Feedback type="invalid">
              {errors[question.id]?.type === "required" &&
                t("request.required")}
            </Form.Control.Feedback>
          </>
        );
        break;
      case "dorm":
        questionParts.push(
          <>
            <hr />
            <p>{t("request.selectDorms")}</p>
            <DormPicker  requester="not1"/>
          </>
        );
        break;
      default:
        questionParts.push(<p className="mb-3">Another type of question...</p>);
    }

    return questionParts;
  };

  const matchesCondition = (
    cond: string,
    questionId: number,
    answer: string
  ): boolean => {
    const splitCond = cond.split(" ");
    if (splitCond[1] === "==") {
      // eslint-disable-next-line eqeqeq
      return splitCond[2] == answer;
    }
    if (splitCond[1] === "is" && splitCond[2] === "null") {
      return (
        !(questionId in showQuestions) || showQuestions[questionId] === false
      );
    }
    if (splitCond[1] === "not" && splitCond[2] === "null") {
      return questionId in showQuestions && showQuestions[questionId] === true;
    }
    return false;
  };

  const doAllQ = (): Question[] => {
    const formValues = getValues();
    const questions: Question[] = [];
    stepQuestions[props.step].forEach((q) => {
      questions.push(q);
      q.childrenQuestions?.forEach((cq) => {
        if (
          !cq.conditionValueRequirement ||
          matchesCondition(cq.conditionValueRequirement, q.id, formValues[q.id])
        ) {
          questions.push(cq);
          return;
        }
      });
    });
    return questions;
  };
  const renderedQuestions = doAllQ()
    .map((q) => renderQuestion(q))
    .flat()
    .map((c, index) => (
      <Form.Group key={`${index}-fg`} className="mb-3">
        {c}
      </Form.Group>
    ));
  return (
    <Form onSubmit={handleSubmit(submit)}>
      {renderedQuestions}

      <Stack
        className="d-flex mb-2 justify-content-center"
        direction="horizontal"
        gap={5}
      >
        <Button
          disabled={props.step === 0 || loading}
          onClick={() => prevStep()}
        >
          {t("request.previous")}
        </Button>
        <Button type="submit" disabled={loading}>
          {props.step === 4
            ? t(loading ? "request.loading" : "request.send")
            : t("request.next")}
        </Button>
      </Stack>

      {!!loading && <p className="mb-2">{t("request.loading")}</p>}
    </Form>
  );
};

export default StepX;
