import {useEffect, useMemo, useState} from "react";
import S3 from "react-aws-s3-typescript";
import {Button, Col, Container, Form, ListGroup, Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {Answer, renderAnswerValue} from "../../models/Request";
import DormPicker from "./DormPicker";
import fetch from "../../fetch";
import {useViewRequestStore} from "./RequestDetails";
import useGlobalStore, {useDormStore} from "../../globalState";
import {Dorm} from "../../models/Dorm";
import {s3Config} from "../StepX";
import {Question} from "../../models/Question";
import {Request} from "../../models/Request";

const RequestDetailsListItem = (props: { question: Question, answer: Answer | null, request: Request }) => {
    const {t} = useTranslation();
    const [isEditing, setEditing] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [validated, setValidated] = useState(false);
    const hasPermission = useGlobalStore(s => s.hasPermission);
    const settings = useGlobalStore(s => s.settings);
    const [value, setValue] = useState(props.answer?.value ?? "");

    useEffect(() => {
        const orderDorms = async () => {
            const dormResults = await fetch(process.env.REACT_APP_API_URL + "/dorms");
            const allDorms = await dormResults.json();

            const dormOptions = JSON.parse(props.answer?.value ?? "[]");
            const setDorms = useDormStore.getState().setDorms;
            const newDormOrder = dormOptions
                .map((dormName: string) => {
                    const dormObj = allDorms.filter((d: Dorm) => d.name === dormName);
                    if (dormObj.length === 0) {
                        return null;
                    }
                    return dormObj[0];
                })
                .filter((d: Dorm | null) => d != null);

            useDormStore.getState().resetDeletedDorms();
            const unusedDorms = allDorms.filter(
                (d: Dorm) => newDormOrder.filter((dorm: Dorm) => dorm.id === d.id).length === 0
            );
            for (const deletedDorm of unusedDorms) {
                useDormStore.getState().addDeletedDorm(deletedDorm);
            }

            setDorms([...newDormOrder, ...unusedDorms]);
        };
        // Initialize values for editing where required.
        if (props.question.type === "dorm") {
            orderDorms();
        }
    }, [props.question.type, props.answer]);

    const renderEditable = () => {
        const question = props.question;
        // const questionParts: any[] = [];
        switch (question.type) {
            case "dropdown":
                return (
                    <Form.Group>
                        <Form.Select
                            aria-label={t(question.text)}
                            value={value}
                            required={!hasPermission('ROLE_COMISIE')}
                            disabled={isLoading}
                            onChange={(e) => setValue(e.target.value)}
                            className="mb-3"
                        >
                            <option>{t('request.selectOption')}</option>
                            {question.properties.options.map((opt: string, index: number) => (
                                <option value={opt} key={`question.id-${index}`}>
                                    {t(opt)}
                                </option>
                            ))}
                        </Form.Select>

                        <Form.Control.Feedback type="invalid">
                            {t("request.required")}
                        </Form.Control.Feedback>
                    </Form.Group>
                );

            case "text":
                return (
                    <Form.Group>
                        <Form.Control
                            className="mb-3"
                            required={!hasPermission('ROLE_COMISIE')}
                            disabled={isLoading}
                            id={`${question.id}-control`}
                            aria-describedby={`${question.id}-hintcontrol`}
                            as={question.properties?.long === true ? "textarea" : "input"}
                            value={value}
                            onChange={(e) => setValue(e.target.value)}
                        />
                        {!!question.hint && (
                            <Form.Text id={`${question.id}-hintcontrol`} muted>
                                {t(question.hint)}
                            </Form.Text>
                        )}
                        <Form.Control.Feedback type="invalid">
                            {t("request.required")}
                        </Form.Control.Feedback>
                    </Form.Group>
                );

            case "date":
                return (
                    <Form.Group>
                        <Form.Control
                            type="date"
                            required={!hasPermission('ROLE_COMISIE')}
                            disabled={isLoading}
                            className="mb-3"
                            id={`${question.id}-control`}
                            aria-describedby={`${question.id}-hintcontrol`}
                            value={value}
                            onChange={(e) => setValue(e.target.value)}
                        />
                        {!!question.hint && (
                            <Form.Text id={`${question.id}-hintcontrol`} muted>
                                {t(question.hint)}
                            </Form.Text>
                        )}

                        <Form.Control.Feedback type="invalid">
                            {t("request.required")}
                        </Form.Control.Feedback>
                    </Form.Group>
                );
            case "file":
                return (
                    <Form.Group>
                        <Form.Control
                            type="file"
                            disabled={isLoading}
                            required
                            onChange={(e: any) => setValue(e.target.files)}
                            className="mb-3"
                            multiple={!question.properties?.single}
                            id={`${question.id}-control`}
                            aria-describedby={`${question.id}-hintcontrol`}
                        />
                        {!!question.hint && (
                            <Form.Text id={`${question.id}-hintcontrol`} muted>
                                {t(question.hint)}
                            </Form.Text>
                        )}

                        <Form.Control.Feedback type="invalid">
                            {t("request.required")}
                        </Form.Control.Feedback>
                    </Form.Group>
                );

            case "dorm":
                return (
                    <>
                        <DormPicker requester="1"/>
                    </>
                );
            default:
                return <p className="mb-3">Another type of question...</p>;
        }
    };

    const answerToShow = useMemo(() => {
        return renderAnswerValue(props.answer, props.question);
    }, [props.answer, props.question]);

    const edit = async (event: any) => {
        event.preventDefault();
        if (isEditing) {
            setValidated(true);
            // Stop submission if field is empty
            const form = event.currentTarget;
            if (form.checkValidity() === false) {
                event.stopPropagation();
                return;
            }
            setLoading(true);

            try {
                const reqBody: any = {value};
                if (props.question.type === "dorm") {
                    const deletedDorms = useDormStore.getState().deletedDormIds;
                    reqBody.value = JSON.stringify(
                        useDormStore
                            .getState()
                            .dorms.filter((d) => !deletedDorms[d.id])
                            .map((d) => d.name)
                    );
                } else if (props.question.type === "file") {
                    if (value.length > 0) {
                        let setStr = props.answer ? props.answer.value + "|" : "";
                        const s3 = new S3(s3Config);
                        for (let i = 0; i < value.length; i++) {
                            const res = await s3.uploadFile(value[i]);
                            setStr += res.location;
                            setStr += "|";
                        }
                        // Remove last |
                        setStr = setStr.slice(0, -1);
                        reqBody.value = setStr;
                        setValue(setStr);
                    }
                }
                if (props.answer) {
                    if (value !== "") {
                        await fetch(`/answers/${props.answer.id}`, {
                            method: "PUT",
                            body: JSON.stringify(reqBody),
                        });
                    } else {
                        await fetch(`/answers/${props.answer.id}`, {
                            method: "DELETE",
                        });
                    }
                } else {
                    reqBody.question = `/questions/${props.question.id}`;
                    reqBody.request = `/requests/${props.request.id}`;
                    await fetch(`/answers`, {
                        method: "POST",
                        body: JSON.stringify(reqBody),
                    });
                }
                const setAns = useViewRequestStore.getState().setAnswerValue;
                setAns(props.question.id, reqBody);
                setEditing(false);
            } catch (e) {
                setValidated(false);
                alert(t("view.editError"));
            }
            setLoading(false);
            return;
        }

        setEditing(true);
    };

    return (
        <ListGroup.Item as="li" className="d-flex">
            <Form
                noValidate
                validated={validated}
                onSubmit={edit}
                className="d-flex justify-content-between align-items-start"
                style={{width: "100%"}}
            >
                <Container fluid>
                    <Row>
                        <Col md={4} className="ms-2" style={{textAlign: "start"}}>
                            <div className="fw-bold"
                                 style={{textDecoration: props.answer ? undefined : "line-through"}}>{t(props.question.text)}</div>
                            {isEditing ? renderEditable() : answerToShow}
                        </Col>
                        <Col></Col>
                        {(settings['usersCanEdit'] || hasPermission('ROLE_COMISIE')) && <Col md={2}>
                            {isEditing && (
                                <Button
                                    type="button"
                                    className="me-1"
                                    variant="danger"
                                    disabled={isLoading}
                                    onClick={() => setEditing(false)}
                                >
                                    {t("view.cancel")}
                                </Button>
                            )}
                            <Button type="submit" disabled={isLoading}>
                                {t(
                                    isEditing
                                        ? "view.save"
                                        : props.question.type === "file"
                                            ? "view.add"
                                            : "view.edit"
                                )}
                            </Button>
                        </Col>}
                    </Row>
                </Container>
            </Form>
        </ListGroup.Item>
    );
};

export default RequestDetailsListItem;
