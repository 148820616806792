import i18next from "i18next";
import {Question} from "./Question";
import {Repartition} from "./Repartition";

export interface Answer {
  question?: string;
  value: any;
  id: number;
}

export interface Student {
  id: number;
  firstName: string;
  lastName: string;
  email?: string;
  cnp: string;
  actIdentitate: string;
  phoneNumber: string;
}

export interface Request {
  id: number;
  createdAt: Date;
  answers: Answer[];
  student: Student;
  status: string;
  checkedBy?: Student;
  repartitions: Repartition[];
}

export const renderAnswerValue = (answer: Answer|null, question: Question|null = null) => {
  if (!answer) {
    return <></>;
  }
  if (!question) {
    return <></>;
  }
  switch (question.type) {
    case "text":
      return <>{answer.value}</>;
    case "dropdown":
      return <>{i18next.t(answer.value)}</>;
    case "date":
      return <>{new Date(answer.value).toLocaleDateString()}</>;
    case "dorm":
      return (
        <ol>
          {JSON.parse(answer.value).map((c: string, index: number) => (
            <li key={`camin-${index}`}>{i18next.t(c).toString()}</li>
          ))}
        </ol>
      );
    case "file":
      return (
        <ul>
          {answer.value.split("|").map((fileUrl: string, index: number) => (
            <li key={`file-${answer.id}-${index}`}>
              <a
                target="_blank"
                rel="noopener noreferrer"
                download
                href={fileUrl}
              >
                Descarcă fișierul {index + 1}
              </a>
            </li>
          ))}
        </ul>
      );
  }
};
