import useGlobalStore from "../../globalState";
import {useState} from "react";
import {useTranslation} from "react-i18next";
import {Button, ListGroup, ListGroupItem, Spinner} from "react-bootstrap";
import DownloadRequest from "./DownloadRequest";
import {useViewRequestStore} from "./RequestDetails";


const RepartitionDetails = (props: { refresh: () => any }) => {
    const {refresh} = props;
    const {t} = useTranslation();
    const settings = useGlobalStore(s => s.settings);
    const answers = useViewRequestStore((s) => s.answers);
    const request = useViewRequestStore((s) => s.requestDetails);
    const hasPermission = useGlobalStore(s => s.hasPermission);
    const [loading, setLoading] = useState(false);

    const changeStatus = async (status: string) => {
        if (request == null) {
            alert(t("request.formError"));
            return;
        }
        setLoading(true);
        try {
            await fetch(`/requests/${request.id}`, {
                method: "PUT",
                body: JSON.stringify({
                    status
                }),
            });

            refresh();
        } catch (e) {
            alert(t("request.formError"));
        } finally {
            setLoading(false);
        }
    }

    if (!request) {
        return <Spinner animation="border"/>
    }

    // Show buttons if they can confirm, otherwise show the download button directly.
    return <>
        {settings["canConfirm"] && !request.status && <>
            {request.repartitions.length === 0 &&
                <Button disabled={loading}
                        onClick={() => changeStatus('redistribution')}>{t('request.applyRedistribution')}</Button>}
            {request.repartitions.length > 0 && <>
                <Button disabled={loading} onClick={() => changeStatus('confirmed')} className="me-2"
                        variant="success">{t('request.confirm')}</Button>
                <Button disabled={loading} onClick={() => changeStatus('waiting')}>{t('request.applyWaiting')}</Button>
            </>}
        </>}

        {(settings["canConfirm"] || hasPermission('ROLE_COMISIE')) && <>
            {request.status === "waiting" && <p>{t('request.inWaiting')}</p>}
            {request.status === "redistribution" && <p>{t('request.inRedistriubtion')}</p>}
            {request.status === "confirmed" && <>
                <p>{t('request.confirmed')}</p>
                <DownloadRequest request={request} answers={answers}/>
            </>}
        </>}


        {!hasPermission('ROLE_COMISIE') && <>
            {!settings["canConfirm"] && request.repartitions.length > 0 && <>
                <DownloadRequest request={request} answers={answers}/>
            </>}

            {!settings["canConfirm"] && request.repartitions.length === 0 && <>
                <p>{t('request.noRepartition')}</p>
            </>}
        </>}

        {request.repartitions.length > 0 && <>
            <h2 className="mt-3" style={{textAlign: "start"}}>
                {t(`comisie.dorm.place`)}
            </h2>
            <ListGroup>
                <ListGroupItem>
                    <div className="ms-2" style={{textAlign: "start"}}>
                        <div className="fw-bold">{t('comisie.dorm.dormField')}</div>
                        <p>{t(request.repartitions[0].room?.dorm?.name ?? '')}</p>
                    </div>
                </ListGroupItem>
                <ListGroupItem>
                    <div className="ms-2" style={{textAlign: "start"}}>
                        <div className="fw-bold">{t('comisie.dorm.roomField')}</div>
                        <p>{request.repartitions[0].room?.number}</p>
                    </div>
                </ListGroupItem>
            </ListGroup>
        </>}
    </>
}

export default RepartitionDetails;