import { Button, Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Dorm } from "../../models/Dorm";
import { useDormStore } from "../../globalState";
const DormListItem = (props: { dorm: Dorm }) => {
  const { t } = useTranslation();

  const isDeleted = useDormStore((s) => s.deletedDormIds[props.dorm.id]);
  const deleteDorm = useDormStore((s) => s.addDeletedDorm);
  const undelete = useDormStore((s) => s.removeDeletedDorm);

  return (
    <Card
      style={{ textDecoration: isDeleted ? "line-through" : undefined, userSelect: 'none' }}
      body
      className="mb-2"
    >
      {t(props.dorm.name)}
      <Button
        className="mx-5"
        variant={isDeleted ? "success" : "danger"}
        onClick={() =>
          isDeleted ? undelete(props.dorm) : deleteDorm(props.dorm)
        }
      >
        {isDeleted ? t("request.readdDorm") : t("request.deleteDorm")}
      </Button>
    </Card>
  );
};

export default DormListItem;
