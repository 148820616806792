import React, {useEffect} from "react";
import { Navigate, Route, Routes } from "react-router-dom";
// import { Col, Container, Row, Image } from "react-bootstrap";
import "./App.css";
// import logo from './assets/logo.webp';
import NaviBar from "./NaviBar";
import Login from "./pages/Login";
import ViewRequest from "./pages/Request";
import FullStory from "fullstory-react";
import { useTranslation } from "react-i18next";
import { Container } from "react-bootstrap";
import useGlobalStore from "./globalState";
import PasswordReset from "./pages/PasswordReset";

const CreateRequest = React.lazy(() => import("./pages/Request/create"));
const Register = React.lazy(() => import("./pages/Register"));
const ComisieRequestList = React.lazy(() => import("./pages/Comisie/Requests"));
const Settings = React.lazy(() => import("./pages/Comisie/Settings"));
const DormList = React.lazy(() => import("./pages/Comisie/Dorms"));
const Repartitions = React.lazy(() => import("./pages/Comisie/Repartitions"));

const ProtectedRoute = (props: {
  children: React.ReactElement;
  requested: string;
}) => {
  if (!props.requested) {
    return props.children;
  }

  if (useGlobalStore.getState().hasPermission(props.requested)) {
    return props.children;
  }

  return <Navigate to="/login" replace />;
};

function App() {
  const { t } = useTranslation();
  useEffect(() => {
    useGlobalStore.getState().getSettings();
  }, []);

  const loading = (
    <Container>
      <p>{t("request.loading")}</p>
    </Container>
  );

  return (
    <FullStory orgId="1572DY">
      <div className="App">
        {/* <Container style={{ height: "100vh" }}> */}
        {/*   <Row className="align-items-center" style={{ height: "100%" }}> */}
        {/*     <Col xs={12} sm={6} className="mx-auto"> */}
        {/*       <Image src={logo} fluid alt="Logo FSAS" /> */}
        {/*     </Col> */}
        {/*     <Col> */}
        {/*       <p>Platforma va deveni activă în scurt timp.</p> */}
        {/*     </Col> */}
        {/*   </Row> */}
        {/* </Container> */}

        <NaviBar />
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route
            path="/register"
            element={
              <React.Suspense fallback={loading}>
                <Register />
              </React.Suspense>
            }
          />
          <Route path="/reset-password" element={<PasswordReset />} />
          <Route path="/reset-password/:id" element={<PasswordReset />} />
          <Route
            path="/request"
            element={
              <React.Suspense fallback={loading}>
                <CreateRequest />
              </React.Suspense>
            }
          />
          <Route path="/success" element={<ViewRequest />} />
          <Route path="/comisie">
            <Route
              path="view"
              element={
                <ProtectedRoute requested="ROLE_COMISIE">
                  <React.Suspense fallback={loading}>
                    <ComisieRequestList />
                  </React.Suspense>
                </ProtectedRoute>
              }
            />
            <Route
                path="settings"
                element={
                  <ProtectedRoute requested="ROLE_ADMIN">
                    <React.Suspense fallback={loading}>
                      <Settings />
                    </React.Suspense>
                  </ProtectedRoute>
                }
            />
              <Route
                  path="dorms"
                  element={
                      <ProtectedRoute requested="ROLE_COMISIE">
                          <React.Suspense fallback={loading}>
                              <DormList />
                          </React.Suspense>
                      </ProtectedRoute>
                  }
              />
              <Route
                  path="rounds"
                  element={
                      <ProtectedRoute requested="ROLE_COMISIE">
                          <React.Suspense fallback={loading}>
                              <Repartitions />
                          </React.Suspense>
                      </ProtectedRoute>
                  }
              />
          </Route>
          <Route path="/*" element={<Navigate to="/login" replace />} />
        </Routes>
      </div>
    </FullStory>
  );
}

export default App;
